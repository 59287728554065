<template>
  <div class="credit-report" v-loading="isLoading">
    <section class="overview">
      <div class="report-log"></div>
      <h1>{{ overviewInfos.shopName | dealNull }}店铺综合评估报告</h1>
      <h3>{{this.$store.state.user.platform.title}}数科星图风控平台</h3>
      <ul class="view-infos">
        <li><label>企业名称：</label>{{ overviewInfos.entName | dealNull }}</li>
        <li><label>店铺ID：</label>{{ overviewInfos.shopId | dealNull }}</li>
        <li>
          <label>所属平台：</label>
          <span v-if="overviewInfos.platform == 10">抖音</span>
          <span v-if="overviewInfos.platform == 11">快手</span>
          <span v-if="overviewInfos.platform == 12">拼多多</span>
          <span v-if="overviewInfos.platform == 13">腾讯视频号</span>
          <span v-if="overviewInfos.platform == 14">美团</span>
          <span v-if="overviewInfos.platform == 15">抖音小时达</span>
        </li>
      </ul>
    </section>

    <div class="main">
      <p class="module-title">店铺风控评估结果</p>
      <RiskResult :moduleInfos="riskResultInfos" v-if="!isLoading" />

      <p class="module-title">店铺信息验证情况</p>
      <InfoVerify :moduleInfos="verifyResInfos" v-if="!isLoading" />

      <p class="module-title">店铺授信评分情况</p>
      <CreditScore :moduleInfos="creditScoreInfos" v-if="!isLoading" />

      <p class="module-title">企业信用画像信息</p>
      <CreditPortraitEnt
        :moduleInfos="creditPortraitEntInfos"
        v-if="!isLoading"
      />

      <p class="module-title">店铺信用画像信息</p>
      <CreditPortraitShop
        :moduleInfos="creditPortraitShopInfos"
        v-if="!isLoading"
      />

      <p class="module-title">反欺诈信息</p>
      <AntiFraud :moduleInfos="antiFraudInfos" v-if="!isLoading" />

      <p class="module-title">规则集命中情况</p>
      <RiskHit :moduleInfos="riskHitInfos" v-if="!isLoading" />
    </div>
  </div>
</template>

<script>
import { doGet, doPost } from "@/router/axios";
import { dealNull } from "@/filters/index";
import {
  RiskResultInfos,
  VerifyResInfos,
  CreditScoreInfos,
  CreditPortraitEntInfos,
  CreditPortraitShopInfos,
  AntiFraudInfos,
  RiskHitInfos,
} from "./service/constant";
// 子组件
import RiskResult from "./components/risk-result.vue";
import InfoVerify from "./components/info-verify.vue";
import CreditScore from "./components/credit-score.vue";
import CreditPortraitEnt from "./components/credit-portrait-ent.vue";
import CreditPortraitShop from "./components/credit-portrait-shop.vue";
import AntiFraud from "./components/anti-fraud.vue";
import RiskHit from "./components/risk-hit.vue";

export default {
  name: "CreditReport",
  filters: { dealNull },
  components: {
    RiskResult,
    InfoVerify,
    CreditScore,
    CreditPortraitEnt,
    CreditPortraitShop,
    AntiFraud,
    RiskHit,
  },
  props: {},
  data() {
    return {
      isLoading: false,
      /** 概览信息 */
      overviewInfos: {},
      /** 店铺风控评估结果 */
      riskResultInfos: RiskResultInfos,
      /** 店铺信息验证情况 */
      verifyResInfos: VerifyResInfos,
      /** 店铺授信评分 */
      creditScoreInfos: CreditScoreInfos,
      /** 企业信用画像信息 */
      creditPortraitEntInfos: CreditPortraitEntInfos,
      /** 店铺信用画像信息 */
      creditPortraitShopInfos: CreditPortraitShopInfos,
      /** 反欺诈信息 */
      antiFraudInfos: AntiFraudInfos,
      /** 规则集命中情况 */
      riskHitInfos: RiskHitInfos,
    };
  },
  watch: {},
  created() {

  },
  mounted() {
    this.getRiskReportData();
  },
  methods: {
    // 获取风控报告数据
    getRiskReportData() {
      this.isLoading = true;
      const { shopId } = this.$route.query;
      this.doPost('/bium-portal/controller/fin-ops/audit/getRiskReport',{
        id:this.$route.query.id,
        mock:true
      })
        .then(({ data }) => {
          if (data && data && data.result) {
            const {
              shopInfo,
              riskResult,
              shopVerifyResult,
              shopCreditScore,
              enterpriseCreditPortrait,
              judicialLitigated,
              shopCreditPortrait,
              antiFraud,
              riskHitInfo,
            } = data.result;
            this.assembleOverviewInfos(shopInfo);
            this.assembleRiskResultInfos(riskResult);
            this.assembleShopVerifyInfos(shopVerifyResult);
            this.assembleShopCreditInfos(shopCreditScore);
            this.assembleEntPortraitInfos(enterpriseCreditPortrait, judicialLitigated);
            this.assembleShopPortraitInfos(shopCreditPortrait);
            this.assembleAntiFraudInfos(antiFraud);
            this.assembleRiskHitInfos(riskHitInfo);
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    // 基本概要
    assembleOverviewInfos(infos) {
        this.overviewInfos = {
          shopName: this.$route.query.storename,
          entName: this.$route.query.entName,
          shopId: this.$route.query.storeCode,
          platform:this.$route.query.livePlatform,
        };
    },
    // 店铺风控评估结果
    assembleRiskResultInfos(infos) {
      if (infos) {
        this.riskResultInfos.resDesc = infos.decision || "";
        this.riskResultInfos.resClass = infos.isPass
          ? "pass-green"
          : "refuse-red";
        this.riskResultInfos.numDatas[0].value = infos.creditLine;
        this.riskResultInfos.numDatas[1].value =
          infos.withdrawDiscount1stRate;
        this.riskResultInfos.numDatas[2].value =
          infos.withdrawDiscountRate;
      }
    },
    // 店铺信息验证情况
    assembleShopVerifyInfos(infos) {
      if (infos) {
        this.verifyResInfos[0].verifyDesc = infos.verifyIdAndNameDesc;
        this.verifyResInfos[1].verifyDesc = infos.verifyIdAndNameAndMobileDesc;
        this.verifyResInfos[2].verifyDesc = infos.verifyCardDesc;
        this.verifyResInfos[3].verifyDesc = infos.verifyAccountAuthDesc;
        this.verifyResInfos[4].verifyDesc = infos.verifySubAccountAuthDesc;
        this.verifyResInfos[5].verifyDesc = infos.verifySuperviseAccountDesc;
        this.verifyResInfos[0].statusClass = infos.verifyIdAndName
          ? "pass-green"
          : "refuse-red";
        this.verifyResInfos[1].statusClass = infos.verifyIdAndNameAndMobile
          ? "pass-green"
          : "refuse-red";
        this.verifyResInfos[2].statusClass = infos.verifyCard
          ? "pass-green"
          : "refuse-red";
        this.verifyResInfos[3].statusClass = infos.verifyAccountAuth
          ? "pass-green"
          : "refuse-red";
        this.verifyResInfos[4].statusClass = infos.verifySubAccountAuth
          ? "pass-green"
          : "refuse-red";
        this.verifyResInfos[5].statusClass = infos.verifySuperviseAccount
          ? "pass-green"
          : "refuse-red";
      }
    },
    // 店铺授信评分情况
    assembleShopCreditInfos(infos) {
      if (infos) {
        const shopCredentialScore = infos.shopCredentialScore || 0;
        const shopOperateScore = infos.shopOperateScore || 0;
        const tripartiteScore = infos.tripartiteScore || 0;
        const agreementHistoryScore = infos.agreementHistoryScore || 0;
        const antiFraudScore = infos.antiFraudScore || 0;
        const enterpriseCredentialScore = infos.enterpriseCredentialScore || 0;
        this.creditScoreInfos.score = infos.creditScore || 0;
        this.creditScoreInfos.chartData = [
          shopCredentialScore,
          shopOperateScore,
          tripartiteScore,
          agreementHistoryScore,
          antiFraudScore,
          enterpriseCredentialScore,
        ];
      }
    },
    // 企业信用画像信息
    assembleEntPortraitInfos(infos, judicial) {
      if (infos) {
        this.creditPortraitEntInfos.score = infos.creditScore || 0;
        this.creditPortraitEntInfos.chartData = [
          { label: "经营范围变更", value: infos.placeChangeCount, unit: "次" },
          { label: "员工人数", value: infos.employeeCount, unit: "人" },
          { label: "社保缴纳人数", value: infos.insurancePayCount, unit: "人" },
          { label: "法人变更", value: infos.directorChangeCount, unit: "次" },
        ];
        this.creditPortraitEntInfos.cardList[0].value = infos.preLoanScore;
        this.creditPortraitEntInfos.cardList[1].value = infos.establishTime;
        this.creditPortraitEntInfos.cardList[2].value = infos.businessPremises;
        this.creditPortraitEntInfos.cardList[3].value = infos.directorAge;
        this.creditPortraitEntInfos.cardList[4].value = infos.directorMaritalStatus;
      }
      if (judicial && judicial.judicialList) {
        this.creditPortraitEntInfos.judicialList = judicial.judicialList;
      }
    },
    // 店铺信用画像信息
    assembleShopPortraitInfos(infos) {
      if (infos) {
        this.creditPortraitShopInfos.score = infos.creditScore || 0;
        this.creditPortraitShopInfos.monthAveragePay = infos.returnMoneyAmount30dAvg;
        this.creditPortraitShopInfos.salesAmt = infos.unsettledAmount;
        this.creditPortraitShopInfos.cardList[0].value = infos.experienceScore;
        this.creditPortraitShopInfos.cardList[1].value = infos.historyBadRate;
        this.creditPortraitShopInfos.cardList[2].value = infos.returnMoneyRate7d;
        this.creditPortraitShopInfos.cardList[3].value = infos.commissionRate30d;
        this.creditPortraitShopInfos.cardList[4].value = infos.pickupRate90d;
        this.creditPortraitShopInfos.cardList[5].value = infos.overdueCount;
        this.creditPortraitShopInfos.cardList[6].value = infos.amortizationCount;
        this.creditPortraitShopInfos.cardList[7].value = infos.returnRate90d;
        this.creditPortraitShopInfos.cardList[8].value = infos.exposureCoverageRate30d;
        this.creditPortraitShopInfos.cardList[9].value = infos.operateCategory;
        this.creditPortraitShopInfos.lineChartData1 = infos.returnMoneyAmount30dList || [];
        this.creditPortraitShopInfos.lineChartData2 = infos.orderAmount30dList || [];
        this.creditPortraitShopInfos.lineChartData3 = infos.returnRate30dList || [];
        this.creditPortraitShopInfos.lineChartData4 = infos.pickupRate30dList || [];
      }
    },
    // 反欺诈信息
    assembleAntiFraudInfos(infos) {
      if (infos) {
        this.antiFraudInfos.fraudDesc = infos.decision;
        this.antiFraudInfos.cardList[0].value = infos.isBadDeviceDesc;
        this.antiFraudInfos.cardList[1].value = infos.isMultiLoanDesc;
        this.antiFraudInfos.cardList[2].value = infos.isBadIpDesc;
        this.antiFraudInfos.cardList[3].value = infos.isFalseMobileDesc;
        this.antiFraudInfos.cardList[0].statusClass = infos.isBadDevice ? "pass-green" : "refuse-red";
        this.antiFraudInfos.cardList[1].statusClass = infos.isMultiLoan ? "pass-green" : "refuse-red";
        this.antiFraudInfos.cardList[2].statusClass = infos.isBadIp ? "pass-green" : "refuse-red";
        this.antiFraudInfos.cardList[3].statusClass = infos.isFalseMobile ? "pass-green" : "refuse-red";
      }
    },
    // 规则集命中情况
    assembleRiskHitInfos(infos) {
      if (infos) {
        this.riskHitInfos.preLoanList = infos.preLoanHitList || [];
        this.riskHitInfos.inLoanList = infos.loanHitList || [];
        this.riskHitInfos.afterLoanList = infos.postLoanHitList || [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.credit-report {
  width: auto;
  min-width: 1200px;
  height: 100%;
  overflow: auto;

  .overview {
    width: 100%;
    height: 200px;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    color: #f5f5f5;
    background: -o-linear-gradient(180deg, #158dff 0%, #435ebe 90%);
    background: -ms-linear-gradient(180deg, #158dff 0%, #435ebe 90%);
    background: -webkit-linear-gradient(180deg, #158dff 0%, #435ebe 90%);
    background: linear-gradient(180deg, #158dff 0%, #435ebe 90%);
    position: relative;
    .report-log {
      width: 125px;
      height: 40px;
      background-repeat: no-repeat;
      background-size: 120px;
      background-image: url("~@/assets/images/logo-reportBimi.png");
      position: absolute;
    }
    h1 {
      margin: 0;
      margin-top: 30px;
      font-size: 30px;
      color: #fff;
    }
    h3 {
      margin-top: 10px;
      font-size: 22px;
      font-weight: normal;
    }
    .view-infos {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 22px;
      font-size: 16px;
      li {
        flex: 1;
        label {
          font-weight: 600;
        }
      }
    }
  }

  .main {
    width: calc(100% - 200px);
    min-width: 1000px;
    margin: 30px auto;
    color: #333;
    .module-title {
      padding-left: 16px;
      margin-top: 20px;
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: 600;
      color: #000;
      position: relative;
      &::before {
        display: block;
        content: " ";
        width: 8px;
        height: 20px;
        border-radius: 5px;
        background: #158dff;
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }

  /deep/.num-data {
    font-size: 16px;
    font-weight: normal;
    strong {
      font-size: 30px;
      color: #29d;
      margin-right: 5px;
    }
  }
  /deep/.pass-green {
    color: #58c820 !important;
  }
  /deep/.refuse-red {
    color: #c00 !important;
  }
  /deep/.sec-box {
    box-sizing: border-box;
    border-radius: 5px;
    background: #e5f0fa;
  }
  /deep/.info-card {
    display: inline-block;
    margin: 5px;
    height: 160px;
    text-align: center;
    padding-top: 32px;
    .name {
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }
    .desc {
      font-size: 14px;
      color: #999;
    }
    .value {
      font-size: 30px;
      font-weight: 600;
      color: #7090f4;
      margin-top: 10px;
      span {
        font-size: 16px;
        margin-left: 5px;
        color: #333;
      }
    }
  }
  /deep/.ordinance-list {
    padding: 20px 50px;
    .title {
      margin-bottom: 16px;
      padding-left: 12px;
      font-size: 18px;
      font-weight: 600;
      color: #29d;
      position: relative;
      &::before {
        display: inline-block;
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: #29d;
        position: absolute;
        top: 10px;
        left: 0;
      }
    }
    .list-item {
      display: flex;
      align-items: center;
      padding: 20px;
      line-height: 22px;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
      border-radius: 5px;
      background: #fff;
      label:nth-child(1) {
        width: 200px;
        box-sizing: border-box;
        padding-left: 25px;
      }
      label:nth-child(2) {
        width: calc(100% - 390px);
        padding-left: 12px;
        color: #999;
      }
      label:nth-child(3) {
        width: 150px;
        padding-left: 30px;
        box-sizing: border-box;
        i {
          font-size: 20px;
          margin-right: 16px;
        }
        &.success {
          color: #58c820;
        }
        &.warning {
          color: #c00;
        }
      }
    }
    .no-ordinance {
      text-align: center;
      font-size: 16px;
      color: #666;
    }
  }
}
</style>
